import mainImage from "./components/ProductPreview/imgs/main.png";
import gallery1 from "./components/ProductPreview/imgs/gallery1.png";
import gallery2 from "./components/ProductPreview/imgs/gallery2.png";
import gallery3 from "./components/ProductPreview/imgs/gallery3.png";

const products = [
    {
        mainImg: {
            src: mainImage,
            alt: ""
        },
        gallery: [
            {
                src: gallery1,
                alt: ""
            },
            {
                src: gallery2,
                alt: ""
            },
            {
                src: gallery3,
                alt: ""
            }
        ],
        heading: "Awnings",
        description: [
            "Awnings offer both style and function, providing shade, energy " +
            "efficiency, and a custom look tailored to your building. We " +
            "fabricate aluminum-frame awnings using durable, low-maintenance " +
            "materials, including high-quality Sunbrella® fabric. Every awning " +
            "is made to order, with custom sizes and shapes available to fit " +
            "your project perfectly."
        ],
        features: [
            "Fabric or Standing Seam",
            "Stock Sunbrella® Material",
            "Custom Sizes & Shapes",
            "Weather-Resistant Design",
            "Commercial & Residential Options",
            "Powder-Coated Aluminum Frames",
        ],
        cta: {
            text: "Learn More",
            target: "/awnings",
            sectionId: "page-content"
        },
        mirrored: false
    },
    {
        mainImg: {
            src: mainImage,
            alt: ""
        },
        gallery: [
            {
                src: gallery1,
                alt: ""
            },
            {
                src: gallery2,
                alt: ""
            },
            {
                src: gallery3,
                alt: ""
            }
        ],
        heading: "Canopies",
        description: [
            "Our aluminum canopies are built to last and designed for function, " +
            "providing protection from the elements with a clean, architectural " +
            "finish. We use standard G and J gutter systems and offer multiple " +
            "fascia sizes to suit your needs. Canopies feature interlocking deck " +
            "pans and include full wall supports, with internal supports available " +
            "for added strength."
        ],
        features: [
            "Standard G & J Gutter Systems",
            '6" or 8" Interlocking Deck Pans',
            '6", 8", 10", or 12" Fascia Options',
            "Durable Aluminum Construction",
            "Wall-Mounted & Internal Support Options",
            "Ideal for Walkways, Doors, & Windows"
        ],
        cta: {
            text: "Learn More",
            target: "/canopies",
            sectionId: "page-content"
        },
        mirrored: true
    },
    {
        mainImg: {
            src: mainImage,
            alt: ""
        },
        gallery: [
            {
                src: gallery1,
                alt: ""
            },
            {
                src: gallery2,
                alt: ""
            },
            {
                src: gallery3,
                alt: ""
            }
        ],
        heading: "Re-Covers",
        description: [
            "Re-covers are a cost-effective way to restore the look and performance " +
            "of your existing awnings or canopies without replacing the entire " +
            "structure. We remove old, worn fabric and replace it with new, " +
            "high-quality Sunbrella® material. This service is ideal for refreshing " +
            "your space while extending the life of your aluminum frames."
        ],
        features: [
            "Quick Turnaround Time",
            "Sunbrella® Fabric Replacement",
            "Fits Existing Frames",
            "Wide Range of Colors & Patterns",
            "Cost-Effective Alternative to New",
            "Commercial & Residential Service"
        ],
        cta: {
            text: "Learn More",
            target: "/re-covers",
            sectionId: "page-content"
        },
        mirrored: false
    },
    {
        mainImg: {
            src: mainImage,
            alt: ""
        },
        gallery: [
            {
                src: gallery1,
                alt: ""
            },
            {
                src: gallery2,
                alt: ""
            },
            {
                src: gallery3,
                alt: ""
            }
        ],
        heading: "Bahama Shutters",
        description: [
            "Bahama shutters add character and sun control while offering storm " +
            "protection and privacy. Made from powder-coated aluminum, these " +
            "shutters are both stylish and durable, with adjustable arms for custom " +
            "positioning. They're a perfect fit for coastal properties and come in a " +
            "wide range of custom colors."
        ],
        features: [
            "Adjustable Tilt Arms",
            "Sun & Storm Protection",
            "Custom Sizes & Colors",
            "Low-Maintenance Finish",
            "Powder-Coated Aluminum Construction",
            "Coastal-Ready Design"
        ],
        cta: {
            text: "Learn More",
            target: "/bahama-shutters",
            sectionId: "page-content"
        },
        mirrored: true
    }
]

export default products;