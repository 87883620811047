import { useRef } from "react";

const useHoverImageSwap = () => {
    const timeoutRef = useRef(null);
    
    return (e, targetId) => {
        const hovered = e.target;
        
        if (hovered.tagName !== "IMG") {
            throw new Error("useHoverImageSwap can only be used on image elements");
        }
        
        const target = document.getElementById(targetId);
        
        if (!target) {
            console.error(`useHoverImageSwap: no element found for "${targetId}"`);
            return;
        }
        
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        
        if (!target.dataset.originalSrc) {
            target.dataset.originalSrc = target.src;
            target.dataset.originalAlt = target.alt;
        }
        
        target.src = hovered.src;
        target.alt = hovered.alt;
        
        hovered.addEventListener("mouseleave", () => {
            timeoutRef.current = setTimeout(() => {
                target.src = target.dataset.originalSrc;
                target.alt = target.dataset.originalAlt;
            }, 300);
        }, { once: true });
    }
}

export default useHoverImageSwap;