import placeholder from "./imgs/placeholder.png";
import development from "./imgs/development-hero.png";
import fabrication from "./imgs/fabrication-hero.png";
import installation from "./imgs/installation-hero.png";

const services = [
    {
        hero: {
            src: placeholder,
            alt: ""
        },
        heading: "On Time, On Budget Development Projects",
        description:
            "Experience with large-scale awning and canopy projects is a MUST when choosing " +
            "a company to sub-contract for commercial awning installation or custom fabrication. " +
            "Awn Tech has been delivering high-end commercial awnings, canopies, and architectural " +
            "aluminum products for over 25 years, working closely with general contractors and " +
            "developers throughout the Tampa Bay region. Our strong track record of completeing " +
            "projects on time, on budget, and with high-quality results has made our commercial " +
            "manufacturing program a trusted choice in Pinellas, Hillsborugh, and Manatee Counties.",
        cta: {
            primary: {
                text: "Contact Us",
                target: "/contact-us",
                sectionId: ""
            },
            secondary: {
                text: "Learn More",
                target: "/",
                sectionId: "faqs",
                scope: "#page-content",
                faqIds: [
                    "faq-products",
                    "faq-years-active",
                    "faq-quote"
                ]
            }
        },
        mirrored: false
    },
    {
        hero: {
            src: placeholder,
            alt: ""
        },
        heading: "Expert Fabrication At Any Scale",
        description:
            "Choosing the right partner for fabrication is critical when your project demands " +
            "precision, durability, and reliability. Awn Tech specializes in custom aluminum " +
            "fabrication solutions tailored to the needs of contractors, developers, and " +
            "architects. With decades of experience in commercial awnings, canopies, and " +
            "architectural products, we ensure every component is built to spec, on schedule, and " +
            "with the highest attention to deail - ideal for both residential and commercial projects.",
        cta: {
            primary: {
                text: "Contact Us",
                target: "/contact-us",
                sectionId: "hero"
            },
            secondary: {
                text: "Learn More",
                target: "/",
                sectionId: "faq-materials",
                scope: "#page-content",
                faqIds: [
                    "faq-materials",
                    "faq-fabrication",
                ]
            }
        },
        mirrored: true
    },
    {
        hero: {
            src: placeholder,
            alt: ""
        },
        heading: "Flawless Installation, Start to Finish",
        description:
            "Proper awning installation is key - and Awn Tech gets it right the first time. " +
            "With a dedicated team of experienced installers, we ensure your awnings, canopies " +
            "and other architectural aluminum products are fitted securely, safely, and efficiently. " +
            "Our commitment to delivering the best possible installation experience makes us " +
            "the most reliable building partner in Tampa Bay.",
        cta: {
            primary: {
                text: "Contact Us",
                target: "/contact-us",
                sectionId: "hero"
            },
            secondary: {
                text: "Learn More",
                target: "/",
                sectionId: "faq-engineering-permitting",
                scope: "#page-content",
                faqIds: [
                    "faq-engineering-permitting",
                ]
            }
        },
        mirrored: false
    },
]

export default services;