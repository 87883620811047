import designIcon from "./imgs/design-icon.png";
import fabricationIcon from "./imgs/fabrication-icon.png";
import installIcon from "./imgs/install-icon.png";

const cards = [
    {
        icon: {
            src: designIcon,
            alt: ""
        },
        heading: "Design",
        description:
            "We carefully assess the specific requirements and preferences " +
            "of our clients, ensuring that each of our custom awnings align " +
            "with their project's function, aesthetic, and goals. By blending " +
            "creative design with technical expertise, we craft awnings and canopies " +
            "that are not only visually distinctive, but structurally sound, and " +
            "tailored to last for long-term performance."
    },
    {
        icon: {
            src: fabricationIcon,
            alt: ""
        },
        heading: "Fabrication",
        description:
            "Our manufacturing process is driven by precision and quality " +
            "craftsmanship. We utilize state-of-the-art machinery and modern " +
            "fabrication methods to ensure that each product meets all state, " +
            "local, and personal specifications. Our team of technicians and " +
            "fabricators build every project from the ground up, so you can be " +
            "your awning or canopy is engineered just for you."
    },
    {
        icon: {
            src: installIcon,
            alt: ""
        },
        heading: "Installation",
        description:
            "With our streamlined installation process, you can rest easy knowing your " +
            "awning or canopy installation will be as fast and painless as possible. We " +
            "focus on structural integrity, proper alignment, and secure fastening, so once " +
            "your awning goes up, it stays up, rain or shine. Whether you need some shade for " +
            "your store front, residential building, or just your backyard, Awn Tech can help " +
            "you beat the heat in no time."
    }
]

export default cards;