import mainImage from "./imgs/main.png";
import gallery1 from "./imgs/gallery1.png";
import gallery2 from "./imgs/gallery2.png";
import gallery3 from "./imgs/gallery3.png";

const content = {
    mainImg: {
        src: mainImage,
        alt: ""
    },
    gallery: [
        {
            src: gallery1,
            alt: ""
        },
        {
            src: gallery2,
            alt: ""
        },
        {
            src: gallery3,
            alt: ""
        }
    ],
    heading: "Type of Service",
    description: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod " +
        "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim " +
        "veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea " +
        "commodo consequat. Duis aute irure dolor in reprehenderit in voluptate."
    ],
    features: [
        "Feature 1",
        "Feature 2",
        "Feature 3",
        "Feature 4",
        "Feature 5",
        "Feature 6"
    ],
    cta: {
        text: "Learn More",
        target: "/contact-us",
        sectionId: "page-content"
    },
    mirrored: false
}

export default content;