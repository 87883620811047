import toggleArrow from "./imgs/toggle-arrow.svg";

const content = {
    toggle: {
        src: toggleArrow,
        alt: ""
    },
    faqs: [
        {
            question: "What areas do Awn Tech Service",
            answer:
                "We proudly serve the St. Petersburg area and surrounding parts of Pinellas, " +
                "Hillsborough, and Manatee counties - anywhere within a two-hour drive " +
                "of Tampa Bay.",
            id: "faq-areas"
        },
        {
            question: "What products do Awn Tech specialize in",
            answer:
                "Awn Tech specializes in custom aluminum architectural products, including " +
                "awnings, canopies, walkways, Bahama shutters, roof scenes, louvers, " +
                "sunshades, railings, and carports.",
            id: "faq-products"
        },
        {
            question: "How long has Awn Tech been in business",
            answer:
                "Awn Tech has been proudly providing the Tampa Bay area custom awning " +
                "installation and fabrication services services for over 29 years.",
            id: "faq-years-active"
        },
        {
            question: "How can I request a quote",
            answer:
                "You can request a custom awning quote by using our contact form below, or " +
                "by giving us a call. We'll gather some basic info and provide a detailed " +
                "estimate tailored to your project.",
            id: "faq-quote"
        },
        {
            question: "Does Awn Tech offer powder coating",
            answer:
                "While Awn Tech doesn't provide in-house powder coating, we coordinate with " +
                "trusted local vendors to ensure your aluminum awning or canopy has a durable, " +
                "weather-resistant finish.",
            id: "faq-powder"
        },
        {
            question: "Can Awn Tech help with engineering and permitting",
            answer:
                "Yes! We handle awning permitting and engineering as part of our process to " +
                "ensure your project complies with all local building codes in the St. " +
                "Petersburg and surrounding Tampa Bay areas.",
            id: "faq-engineering-permitting"
        },
        {
            question: "What materials do you use for your products",
            answer:
                "We primarily with marine grade aluminum due to its strength, rust resistance, " +
                "and durability, as well as Sunbrella® high-performance fabrics - both ideal for " +
                "Florida's coastal climate.",
            id: "faq-materials"
        },
        {
            question: "Do you offer custom fabrication",
            answer:
                "Absolutely! All of our awnings, canopies, and Bahama shutters are built through " +
                "custom fabrication, tailored to your exact site adn design needs, and never " +
                "off-the-shelf solutions.",
            id: "faq-fabrication"
        }
    ]
}

export default content;