import styles from "./NotFound.module.scss";
import { ButtonTypeA as Button } from "../Buttons";

const useSendToSection = () => {
    const sendToSection = (sectionId, scope = document) => {
        setTimeout(() => {
            let container = document.documentElement;
            
            if (scope !== document) {
                container = document.querySelector(scope);
            }
            
            if (!container) {
                console.error(`Error: element ${scope} not found`);
                return;
            }
            
            const section = document.getElementById(sectionId);
            
            if (section && container) {
                const sectionPosition = section.offsetTop - (container.offsetTop || 0);
                
                container.scrollTo({
                    top: sectionPosition,
                    behavior: "smooth"
                });
            }
        }, 100);
    }
    
    return sendToSection;
}

const NotFound = () => {
    const sendToSection = useSendToSection();
    return (
        <main key={location.pathname} id="page-content">
            <div className={styles.notFound} id="hero">
                <h1 className={styles.heading}>
                    Coming Soon!
                </h1>
                <p className={styles.description}>
                    Just like our custom aluminum awnings and canopies,
                    this website is being built to last. Stay tuned!
                </p>
                <Button
                    text={"Home"}
                    target={"/"}
                    onClick={() => sendToSection("page-content")}
                />
            </div>
        </main>
    )
}

export default NotFound;