import styles from "./ProductPreview.module.scss";
import fallback from "./content";
import { ButtonTypeB as Button } from "../../../Buttons";
import { useSendToSection } from "../../../../hooks";
import { useHoverImageSwap } from "./hooks";

const ProductPreview = ({ idx, content = fallback }) => {
    const {
        mainImg, gallery, heading, description,
        features, cta, mirrored
    } = content;
    const sendToSection = useSendToSection();
    const hoverImageSwap = useHoverImageSwap();
    
    return (
        <section className={`${styles.product} ${mirrored ? styles.reverse : ""}`}>
            <div className={styles.container}>
                <div className={`${styles.displayBlock} ${mirrored ? styles.reverse : ""}`}>
                    <div className={styles.hero}>
                        <img id={`main-img-${idx}`} src={mainImg.src} alt={mainImg.alt} />
                    </div>
                    <Button
                        text={cta.text}
                        target={cta.target}
                        onClick={(e) => {
                            const isSamePage = location.pathname === cta.target;
                            if (isSamePage) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                            sendToSection("page-content", target);
                        }}
                    />
                </div>
                <div className={`${styles.contentBlock} ${mirrored ? styles.reverse : ""}`}>
                    <div className={styles.descriptionBlock}>
                        <h2 className={styles.heading}>{heading}</h2>
                        <div className={styles.description}>
                            {description.map((paragraph, id) => (
                                <p key={id}>{paragraph}</p>
                            ))}
                        </div>
                    </div>
                    <div className={styles.featuresBlock}>
                        <p className={styles.featureTitle}>Features</p>
                        <ul className={styles.features}>
                            {features.map((feature, id) => (
                                <li key={id}>{feature}</li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.gallery}>
                        {gallery.map(({ src, alt }, id) => (
                            <img
                                key={id} src={src} alt={alt}
                                onMouseEnter={(e) => hoverImageSwap(e, `main-img-${idx}`)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductPreview;